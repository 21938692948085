<template>
<div>
  <Card :bordered="false" style="margin-bottom: 12px" dis-hover>
    <p slot="title">业务工单</p>
    <Row :gutter="16">
      <Col span="4">
        <Card bordered>
          机器业务
        </Card>
      </Col>
      <Col span="4">
        <a @click="isShow=true">
          <Card bordered>
            <strong>机器下架</strong>
            <base-drawer :is-show="isShow" @closeDrawer="closeDrawer">
              <Form :model="formData" label-colon>
              <Card  style="margin-bottom: 8px"  bordered>
                <p slot="title">公共信息</p>
                <FormItem label="工单标题" label-position="left"  :label-width="80">
                  <Input v-model="formData.name" placeholder="工单标题" />
                </FormItem>
                <FormItem label="优先级" :label-width="80">
                  <RadioGroup v-model="formData.radio">
                    <Radio label="1" style="color: #2d8cf0; margin-right: 18px">正常</Radio>
                    <Radio label="2" style="color: #ff9900; margin-right: 18px">紧急</Radio>
                    <Radio label="3" style="color: #ed4014">非常紧急</Radio>
                  </RadioGroup>
                </FormItem>
                <FormItem label="处理人" :label-width="80">
                  <Select v-model="formData.owner" placeholder="请选择处理人">
                    <Option value="1">史莱克</Option>
                    <Option value="2">处理人2</Option>
                  </Select>
                </FormItem>
                <FormItem label="期望完成时间" :label-width="80">
                  <DatePicker type="date" placeholder="Select date" style="width: 200px"></DatePicker>
                </FormItem>
              </Card>
              <Card bordered>
                <p slot="title">工单详情</p>
                <FormItem>
                  <Input type="textarea" v-model="formData.desc" :rows="4" placeholder="工单内容" />
                </FormItem>
              </Card>
              </Form>
            </base-drawer>
          </Card>
        </a>
      </Col>
    </Row>
  </Card>
  <Card :bordered="false" dis-hover>
    <p slot="title">故障工单</p>
    <Row :gutter="16">
      <Col span="4">
        <Card bordered>
          机器硬件
        </Card>
      </Col>
      <Col span="4">
        <Card bordered>
          带宽异常
        </Card>
      </Col>
    </Row>
  </Card>
</div>
</template>

<script>
import BaseDrawer from "@/components/base/baseDrawer";
export default {
  name: "WorderCreate",
  components: {
    BaseDrawer
  },
  data() {
    return {
      isShow: false,
      formData: {}
    }
  },
  methods: {
    closeDrawer() {
      this.isShow = false;
    }
  }
}
</script>

<style scoped>

</style>